import React from "react"
import { navigate } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { use100vh } from "react-div-100vh"
import { SiteMetaConfig, FormConfig } from "gatsby-theme-0/src/config/config-data"
import SizeFix from "gatsby-theme-0/src/utils/sizefix"
import "../templates/post.css"


const GiveawayLastPage = ({ location }) => {

  const customUniqueID = new URLSearchParams(location.search).get('uniqueid');
  const uniqueIdCondition = (location.state && location.state.UniqueID) || customUniqueID;
  React.useEffect(() => {
    if (!uniqueIdCondition) {
      navigate("/");
    };
  }, []);
  

  const [realPassword, setRealPassword] = React.useState('')
  const handlePasswordChange = event => {
    setRealPassword(event.target.value);
  };
  

  const [paragraph, setParagraph] = React.useState('');
  const paragraphMinimumLength = 40;


  const GiveawaySubmit = (event) => {
    event.preventDefault();
    const giveawayData = new FormData(event.currentTarget);
    giveawayData.delete('Password');
    const giveawayValues = Object.fromEntries(giveawayData.entries());
    // const giveawayValuesCombined = { ...giveawayValues, ...location.state.PreviousData };
    if (paragraph.replace(/\s/g, "").length < paragraphMinimumLength) {
      alert("STOP TRYING TO HACK")
    } else {
      if (realPassword.trim().length !== 0) {
        {FormConfig.form_spam_email &&
          // Spam collection endpoint
          fetch(FormConfig.form_spam_email, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            body: JSON.stringify(giveawayValues)
          })
        }
      } else {
        fetch(`${FormConfig.form_firebase_link}giveaway/${uniqueIdCondition}.json?auth=${FormConfig.form_firebase_auth}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify(giveawayValues)
        })
      }
    }
  };


  const PurchaseSubmit = (event) => {
    event.preventDefault();

    const purchaseData = new FormData(event.currentTarget);
    purchaseData.delete('Password');
    const purchaseValues = Object.fromEntries(purchaseData.entries());
    // const purchaseValuesCombined = { ...purchaseValues, ...location.state.PreviousData };
    if (realPassword.trim().length !== 0) {
      {FormConfig.form_spam_email &&
        // Spam collection endpoint
        fetch(FormConfig.form_spam_email, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify(purchaseValues)
        })
      }
    } else {
      // Mind change purchase form endpoint
      fetch(`${FormConfig.form_firebase_link}giveaway/${uniqueIdCondition}.json?auth=${FormConfig.form_firebase_auth}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(purchaseValues)
      })
    }
  };

  return (
  <>
    <GatsbySeo
      title={`Final step of form submission | ${SiteMetaConfig.site_name}`}
      description={SiteMetaConfig.site_description}
      canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
      openGraph={{
        type: "website",
        url: `${SiteMetaConfig.site_url}${location.pathname}`,
        title: `Final step of form submission | ${SiteMetaConfig.site_name}`,
        description: SiteMetaConfig.site_description,
        images: [
          { url: SiteMetaConfig.site_image },
        ],
        site_name: SiteMetaConfig.site_name,
      }}
      twitter={{
        handle: `@${SiteMetaConfig.site_twitter_handle}`,
        site: `@${SiteMetaConfig.site_name}`,
        cardType: "summary_large_image",
      }}
      facebook={{
        appId: SiteMetaConfig.site_facebook_app_id,
      }}
      htmlAttributes={{
        lang: SiteMetaConfig.site_language,
      }}
      noindex={true}
      nofollow={true}
    />
    <SizeFix />
    <div id="giveawaylast_1662984629965" style={{minHeight: use100vh(), display: "grid", alignItems: "center"}}>
      {uniqueIdCondition &&
      <div id="giveawaylast_1662984631339" className="Card Card_Container">
        <div id="giveawaylast_1662984633091" className="Buy_Card">
          <div id="giveawaylast_1662984634700" className="Sub_Heading_Text">Congratulation you have reached the last step!</div>
          <form id="giveawaylast_1662984636163" onSubmit={GiveawaySubmit}>
            <label className="Real_Password" htmlFor="Password-1">Password:</label>
            <input
              id="Password-1"
              name="Password"
              type="password"
              className="Real_Password"
              onChange={handlePasswordChange}
              autoComplete="new-password"
            />
            <div id="giveawaylast_1662984638820" style={{textAlign:"left"}}>
              <label className="Text_Card">
                <div id="giveawaylast_1662984640547" className="Normal_Text">To qualify for our weekly giveaway please write a paragraph about what do you think about our service (Required):</div>
                <textarea id="giveawaylast_1662984642083" name="Paragraph" className="Text_Input" value={paragraph} onChange={(event) => setParagraph(event.target.value)}/>
              </label>
            </div>
            <input id="giveawaylast_1662984644268" type="hidden" name="Giveaway Conclusion" value="Giveaway Request" />
            {(paragraph.replace(/\s/g, "").length > 0 && paragraph.replace(/\s/g, "").length < paragraphMinimumLength) &&
            <>
              <span id="giveawaylast_1662984650611" style={{fontSize:"var(--Text_Size_Input)"}} className="Form_Warning_Text">
                The paragraph is too short. Required {Math.abs(paragraph.replace(/\s/g, "").length - paragraphMinimumLength)} more charaters
              </span>
              <div id="giveawaylast_1662984652211" className="Extra_Spacing" />
            </>
            }
            <button id="giveawaylast_1662984654779" type="submit" onClick={() => {navigate("/thankyou/")}} disabled={paragraph.replace(/\s/g, "").length < paragraphMinimumLength}>APPLY</button>
          </form>
        </div>
        <div id="giveawaylast_1663691047177" className="Extra_Spacing" />
        <div id="giveawaylast_1662984657891" className="Buy_Card">
          <div id="giveawaylast_1662984668099" style={{borderBottom: "none", color: "var(--Secondary)"}} className="Sub_Heading_Text">Or you can buy it by clicking the button below!</div>
          <form id="giveawaylast_1662984670187" onSubmit={PurchaseSubmit}>
            <label className="Real_Password" htmlFor="Password-2">Password:</label>
            <input
              id="Password-2"
              name="Password"
              type="password"
              className="Real_Password"
              onChange={handlePasswordChange}
              autoComplete="new-password"
            />
            <input id="giveawaylast_1662984672178" type="hidden" name="Giveaway Conclusion" value="Purchase Request" />
            <button id="giveawaylast_1662984674251" type="submit" onClick={() => {navigate("/thankyou/")}}>GET IT NOW</button>
          </form>
        </div>
      </div>
      }
    </div>
  </>
  );
}

export default GiveawayLastPage